body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-attachment: fixed;
  background-image: url(./assets/background_summercamp.jpg);
  background-position: top left;
  background-size: cover;
  background-color: rgb(255, 140, 0);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type="file"] {
  width: 100%;
}
