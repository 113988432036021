.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .Footer {
  text-align: center;
}
