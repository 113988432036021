.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .Footer {
  text-align: center;
}

.Agb {
  padding: 80px 0;
  text-align: left;
}

.DownloadButton.btn {
  border-radius: 0px;
}

.ResponsiveVideoPlayer .playerWrapper {
  position: "relative";
  padding-top: "52%";
}

.ResponsiveVideoPlayer .reactPlayer {
  position: "absolute";
  top: 0;
  left: 0;
}

.Home .lander {
  padding: 60px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 400;
}

.Home .lander h2 {
  font-weight: 300;
  margin-bottom: 1em;
}

@media all and (min-width: 480px) {
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .Settings {
    padding: 60px 0;
  }

  .Settings form {
    margin: 0 auto;
    max-width: 480px;
  }
}

@media all and (min-width: 480px) {
  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-attachment: fixed;
  background-image: url(/static/media/background_summercamp.1b889a7e.jpg);
  background-position: top left;
  background-size: cover;
  background-color: rgb(255, 140, 0);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type="file"] {
  width: 100%;
}

