.ResponsiveVideoPlayer .playerWrapper {
  position: "relative";
  padding-top: "52%";
}

.ResponsiveVideoPlayer .reactPlayer {
  position: "absolute";
  top: 0;
  left: 0;
}
