.Home .lander {
  padding: 60px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 400;
}

.Home .lander h2 {
  font-weight: 300;
  margin-bottom: 1em;
}
